<template>
  <v-dialog v-model="dialog" persistent max-width="1000">
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">{{ $t("choose_data_type") }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <div class="select-main pa-2">
          <v-card
            v-for="(type, i) in types"
            :key="i"
            class="select-item ma-4 py-8 text-center col-12 col-sm-6 col-md-4 col-lg-3"
            @click="selectForm(type)"
          >
            <img class="select-img" :src="type.image" />
            <div class="text-h5 font-weight-bold pt-6">{{ type.text }}</div>
          </v-card>
        </div>
      </div>
    </div>
    <ImportData
      ref="dataImporter"
      :import-type-form="getImportForm"
      :importTypeRelatedData="getImportData"
      :updateTableContent="updateTableContent"
    />
  </v-dialog>
</template>

<script>
import ImportData from "./ImportData.vue";
import OrderForm from "./forms/OrderForm.vue";
import CodForm from "./forms/CodForm.vue";
import OrderStatusForm from "./forms/OrderStatusForm.vue";
import SkuBundleForm from "./forms/SkuBundleForm.vue";
import SkuForm from "./forms/SkuForm.vue";
import SkuIntegrationForm from "./forms/SkuIntegrationForm.vue";
import AsnForm from "./forms/AsnForm.vue";
import SkuUpdateForm from "./forms/SkuUpdateForm.vue";
import AlterSkuForm from "./forms/AlterSkuForm.vue";
import ProductForm from "./forms/ProductForm.vue";
import ProductUpdateForm from "./forms/ProductUpdateForm.vue";
import CourierStatusForm from "./forms/CourierStatusForm.vue";
import OrderB2BForm from "./forms/OrderB2BForm.vue";
import OrderRCOForm from "./forms/OrderRCOForm.vue";
import SkuCategoryForm from "./forms/SkuCategoryForm.vue";
import SkuBrandForm from "./forms/SkuBrandForm.vue";
import AsnSkuForm from "./forms/AsnSkuForm.vue";
import StoreForm from "./forms/StoreForm.vue";
import ReturnForm from "./forms/ReturnForm.vue";
import WarehouseExecutionForm from "./forms/WarehouseExecutionForm.vue";
import BaqAsnForm from "./forms/BaqAsnForm.vue";
import LocationMappingForm from "./forms/LocationMappingForm.vue";
import OrganizationAddressBook from "./forms/OrganizationAddressBook.vue";
import UMOConversions from "./forms/UMOConversions.vue";
import DispatchVehicleForm from "./forms/DispatchVehicleForm.vue";
import ChutesForm from "./forms/ChutesForm.vue";
import WesAccountsForm from "./forms/WesAccountsForm.vue";
import WesSplBarcodeForm from "./forms/WesSplBarcodeForm.vue";
import SortingLocationForm from "./forms/SortingLocationForm.vue";
import WesSplSortingItemForm from "./forms/WesSplSortingItemForm.vue";
import SortationSKUs from "./forms/SortationSKUs.vue";
import StandardReceivingForm from "./forms/StandardReceivingForm.vue";
import InboundPackagingForm from "./forms/InboundPackagingForm.vue";
import StandardPutawayForm from "./forms/StandardPutawayForm.vue";
import StandardOrderPickingForm from "./forms/StandardOrderPickingForm.vue";
import StandardOrderPackagingForm from "./forms/StandardOrderPackagingForm.vue";
import StandardOrderDispatchForm from "./forms/StandardOrderDispatchForm.vue";

export default {
  name: "UploadSelect",
  components: {
    ImportData,
  },
  props: {
    types: {
      required: true,
      type: Array,
    },
    updateTableContent: {
      required: true,
    },
    pageLoad: {
      required: true,
    },
    uploadTypeData: {
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    uploadComponent: null,
    importForm: null,
    importData: null,
  }),
  mounted() {},
  beforeMount() {},
  computed: {
    getImportForm() {
      return this.importForm;
    },
    getImportData() {
      return this.importData;
    },
  },
  methods: {
    dataForForm(dataType) {
      let data = null;
      this.types.forEach((type) => {
        if (type.type === dataType) {
          data = type;
        }
      });
      return data;
    },
    toggleUploadModal() {
      this.dialog = !this.dialog;
      if (this.uploadTypeData) {
        this.$nextTick(() => {
          const upload = this.types.filter(
            (type) => type.type === this.uploadTypeData
          )[0];
          this.selectForm(upload);
        });
      }
    },
    selectForm(type) {
      switch (type.type) {
        case "order":
          this.importForm = OrderForm;
          break;
        case "order_status":
          this.importForm = OrderStatusForm;
          break;
        case "cod":
          this.importForm = CodForm;
          break;
        case "sku":
          this.importForm = SkuForm;
          break;
        case "sku_bundle":
          this.importForm = SkuBundleForm;
          break;
        case "sku_integration":
          this.importForm = SkuIntegrationForm;
          break;
        case "sku_update":
          this.importForm = SkuUpdateForm;
          break;
        case "sku_code":
          this.importForm = AlterSkuForm;
          break;
        case "asn":
          this.importForm = AsnForm;
          break;
        case "standard_order":
          this.importForm = AsnForm;
          break;
        case "fulfillment_product":
          this.importForm = ProductForm;
          break;
        case "fulfillment_product_update":
          this.importForm = ProductUpdateForm;
          break;
        case "courier_status":
          this.importForm = CourierStatusForm;
          break;
        case "orderB2B":
          this.importForm = OrderB2BForm;
          break;
        case "sku_category":
          this.importForm = SkuCategoryForm;
          break;
        case "sku_brand":
          this.importForm = SkuBrandForm;
          break;
        case "asn_sku":
          this.importForm = AsnSkuForm;
          break;
        case "client_store":
          this.importForm = StoreForm;
          break;
        case "return":
          this.importForm = ReturnForm;
          break;
        case "sorting_locations":
          this.importForm = SortingLocationForm;
          break;
        case "chutes":
          this.importForm = ChutesForm;
          break;
        case "stations":
          this.importForm = WarehouseExecutionForm;
          break;
        case "baq_asn":
          this.importForm = BaqAsnForm;
          break;
        case "orderRCO":
          this.importForm = OrderRCOForm;
          break;
        case "location_mappings":
          this.importForm = LocationMappingForm;
          break;
        case "organization_address_books":
          this.importForm = OrganizationAddressBook;
          break;
        case "standard_asn":
          this.importForm = AsnForm;
          break;
        case "uom_conversions":
          this.importForm = UMOConversions;
          break;
        case "dispatch_vehicle":
          this.importForm = DispatchVehicleForm;
          break;
        case "sortation_skus":
          this.importForm = SortationSKUs;
          break;
        case 'wes_accounts':
          this.importForm = WesAccountsForm;
          break;
        case "spl_barcodes":
          this.importForm = WesSplBarcodeForm;
          break;
        case "spl_categories":
          this.importForm = WesSplSortingItemForm;
          break;
        case "standard_receiving":
          this.importForm = StandardReceivingForm;
          break;
        case "inbound_packaging":
          this.importForm = InboundPackagingForm;
          break;
        case "standard_putaway":
          this.importForm = StandardPutawayForm;
          break;
        case "standard_order_picking":
          this.importForm = StandardOrderPickingForm;
          break;
        case "standard_order_packaging":
          this.importForm = StandardOrderPackagingForm;
          break;
        case "standard_order_dispatch":
          this.importForm = StandardOrderDispatchForm;
          break;
      }
      this.importData = type;
      this.$nextTick(() => {
        this.$refs.dataImporter.toggleModal();
      });
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.select-item {
}
.select-img {
  width: 6rem;
  height: 6rem;
}
.select-main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
}
</style>
